<template>
    <v-card class="secondary">
    <v-card-title>
      Pedidos de Autorização
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-btn dark class="mb-2" :disabled="requests.length > 0" :to="newPageRoute">
        Pedido Perfil Festival
      </v-btn>
    </v-card-title>
    <v-data-table class="secondary"
      :headers="headers"
      :items="requests"
      :search="search"
      :loading="loading"
    >
      <template v-slot:[`item.validationDate`]="{ item }">
          <span>{{ item.validationDate || '- - - - - - - - - - - -' }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
          <span :class="item.status == 'APPROVED' ? 'primary--text' : 'red--text'">{{ item.status }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn class="mr-2" small icon :to="item.$route.detail">
          <v-icon  color="info"  >
            mdi-eye-outline
          </v-icon>
        </v-btn>
        <!--v-btn small class="mr-2" icon :to="item.$route.edit" v-if="item.status != 'APPROVED'">
          <v-icon  color="info" >
            mdi-pencil
          </v-icon>
        </v-btn-->
        <!--v-icon color="error" small v-if="item.status != 'APPROVED'">
          mdi-delete
        </v-icon-->
      </template>
    </v-data-table>
    <v-container v-if="hasMore">
      <v-row align="center" justify="center">
        <v-btn 
          :loading="loading" 
          :disabled="loading"
          @click="fetchMore">Carregue Mais</v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { profileDashboard } from '../../../../routes';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    data () {
      return {
        newPageRoute: profileDashboard.festival.authorizationRequest.new,
        search: '',
        headers: [
          {
            text: 'Data Pedido',
            align: 'start',
            value: 'requestDate',
          },
          {
            text: 'Data Validação',
            align: 'start',
            value: 'validationDate',
          },
          {
            text: 'Status',
            value: 'status',
          },
          { text: 'Actions', value: 'action', sortable: false },
        ],
      }
    },
    created () {
        this.fetchReuqests(this.filter);
    },
    computed: {
        ...mapGetters('dashboard/festivalRequest', [ 'hasMore']),
        ...mapState('dashboard/festivalRequest', ['requests', 'hasMore', 'loading']),
        filter() {
            return { 
                limit: 20,
                profile: this.$user.info.id,
            }
        }
    },
    methods: {
      ...mapActions('dashboard/festivalRequest', {
          fetchReuqests: 'list',
          loadMore: 'loadMore',
      }),
      fetchMore() {
          this.loadMore(this.filter);
      },
    }
  }
</script>